.MainContainer {
  display: none;
}

.FirstContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.SecondContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* width: 100%; */
  padding: 24px;
  margin-bottom: 40px;
}

.UnordList {
  padding: 0;
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.ListItem {
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  text-indent: 0;
  list-style-type: none;
  background-color: #707070;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 1.5;
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
}

.ListItem:not(:last-child):after {
  content: '';
  position: absolute;
  top: calc((100% - 5%) / 2);
  height: 4%;
  z-index: 0;
  background-color: #707070;
  color: #ffffff;
  width: calc((100% - 2rem) / 4);
}

.ContentContainer {
  max-width: 309px;
}

.Title {
  font-family: ModernEra;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Msg {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}

@media (min-width: 769px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
  }
}

@media (min-width: 1300px) {
  .ListItem:not(:last-child):after {
    width: calc((100% - 2rem) / 3);
  }

  .UnordList {
    justify-content: space-around;
  }
}
