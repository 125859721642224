.MainContent {
  margin-top: 32px;
  padding: 0px 32px;
}

.NeedMedContainer {
  background-color: #ecfdf5;
  padding: 32px;
}

.Detail {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 16px;
}

.Price {
  font-family: ModernEra;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.NeedMedPriceContainer {
  margin-bottom: 32px;
}

.PossAdvPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ModPrice, .ComplexPrice {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ComplexPrice {
  margin-top: 16px;
}

@media (min-width: 414px) {
  .PossAdvPrices {
    flex-wrap: nowrap;
  }

  .ComplexPrice {
    margin-top: 0px;
  }
}


@media (min-width: 769px) {
  .Detail {
    font-size: 26px;
  }

  .Price {
    font-size: 34px;
  }

  .NeedMedContainer {
    margin-top: 48px;
    border-radius: 24px 24px 24px 24px;
    padding: 48px;
  }

  .DetailContainerNoCand {
    padding: 0 10%;
  }
}

@media (min-width: 920px) {
  .DetailContainer {
    max-width: 80%;
  }

  .NeedMedContainer {
    padding-left: 80px;
    padding-right: 80px;
  }
}
