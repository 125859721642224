.MainContainer {
  display: none;
}

.Container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 638px;
}

.Heading {
  font-family: QuincyCF;
  font-size: 34px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 60px;
}

.ModerateContainer, .ComplexContainer {
  width: 414px;
  background-color: #ecfdf5;
  border-radius: 24px;
  height: inherit;
}

@media(min-width: 1050px) {
  .MainContainer {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 890px) {
  .Container {
    height: 553px;
  }
}

@media (min-width: 951px) {
  .Container {
    height: 502px;
  }
}

@media (min-width: 962px) {
  .Container {
    height: 476px;
  }
}

@media (min-width: 1024px) {
  .Container {
    height: none;
  }
}
