.CarouselMovil {
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.SlideshowContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.SliderMove {
  display: flex;
  margin-left: 7%;
  align-items: center;
}

.StepContainerImages {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.8s;
}

.SliderActive {
  border: none;
  width: 10px;
  height: 10px;
  background-color: #99bffc;
  border-radius: 5px;
  z-index: 100;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  outline: none;
}

.SliderBootOut {
  position: static;
  align-self: center;
  bottom: 5px;
  margin-top: 30px;
  z-index: 0;
  height: 20px;
  text-align: center;
}

.SliderBotton {
  border: none;
  transition: opacity .25s;
  width: 6px;
  height: 6px;
  background-color: #d8d8d8;
  border-radius: 3px;
  z-index: 100;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.CarouselHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.HeaderTitle{
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin: 0px 5px 0;
}

.ImageSlider { 
  width: 100%;
}

@media (min-width: 700px) {
  .CarouselMovil {
      display: none;
  }
}