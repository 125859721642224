.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dee3f2;
  margin-top: 48px;
  padding: 20px 32px;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

@media (min-width: 769px) {
  .Text {
    font-size: 20px;
    line-height: 1.2;
    max-width: 668px;
  }
}