.MainContent {
  background-color: #ecfdf5;
  margin-top: 32px;
}

.Heading {
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-top: 32px;
}

.ComplexityContainer {
  margin-top: 24px;
}

.SwitchButton {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  background-color: #ffffff;
  padding: 14px 22px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 0;
  cursor: pointer;
}

.ModerateButton {
  border-radius: 32px 0px 0px 32px;
}

.ComplexButton {
  border-radius: 0px 32px 32px 0px;
}

.ActiveButton {
  background-color: #0752f9 !important;
  color: #f8f8f8 !important;
}

@media (min-width: 769px) {
  .MainContent {
    border-radius: 24px 24px 24px 24px;
  }
}

@media (min-width: 1050px) {
  .MainPossAdv {
    display: none;
  }
}