.PromoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PromoText {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  /* position */
  position: absolute;
  top: 8px;
  left: 32px;
  display: flex;
  flex-direction: column;
}

.Image {
  position: relative;
}

.Image img {
  max-width: 100%;
  object-fit: contain;
}

.Description {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 0px 32px;
}

.ShareContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.ClipboardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 7px;
  background-color: #eaeaea;
  padding: 5px 10px;
  /* width: 215px; */
  width: 110%;
  margin-bottom: 24px;
}

.YourCodeLabel {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.DiscountCode {
  font-family: QuincyCF;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.CopyButton {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
  cursor: pointer;
}

.Note {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 24px;
}

.SocialNetworks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SocialNetLabel {
  font-family: ModernEra;
  font-size: 8px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-bottom: 32px;
}

.Whatsapp, .Facebook {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Whatsapp {
  margin-right: 36px;
}

.CircleBtn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
}

.WhatsappBtn {
  background-color: #1bd741;
  margin-bottom: 8px;
}

.WhatsappIcon {
  background-image: url('../../Assets/images/svg/whatsapp.svg');
  display: block;
  width: 19px;
  height: 22px;
  background-repeat: no-repeat;
  margin-left: 15px;
  margin-top: 14px;
}

.FacebookBtn {
  background-color: #4167b1;
  margin-bottom: 8px;
}

.FacebookIcon {
  background-image: url('../../Assets/images/svg/facebook.svg');
  display: block;
  width: 11px;
  height: 21px;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 14px;
}

.TermsCond {
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
  cursor: pointer;
  text-decoration: underline;
}

@media (min-width: 350px) {
  .PromoText {
    font-size: 20px;
  }
}

@media (min-width: 769px) {
  .PromoText {
    top: 32px;
    font-size: 32px;
  }

  .Description {
    font-size: 26px;
  }

  .Image img {
    border-radius: 24px 24px 0px 0px;
  }

  .YourCodeLabel, .CopyButton, .Note, .TermsCond {
    font-size: 22px;
  }

  .DiscountCode {
    font-size: 26px;
  }

  .ClipboardContainer {
    padding: 10px 20px;
  }

  .SocialNetLabel {
    font-size: 10px;
  }
}

@media (min-width: 881px) {
  .PromoText {
    font-size: 38px;
  }
}