.PageContainer {
  width: '100%';
  position: relative;
  min-height: 100vh;
}

.Content {
  padding-bottom: 87px;
}

.BtnContainer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 16px;
  z-index: 2;
}

.MaxContainerCTA {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnContainerContact {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 16px;
  z-index: 2;
  margin-top: 32px;
  margin-bottom: 32px;
}

.isSticky {
  box-shadow: none;
  background-color: transparent;
}

.BookText {
  display: none;
}

.ActionBtn, .ActionBtnContact {
  /* text */
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  /* btn */
  cursor: pointer;
  background-color: #0752f9;
  border: 0;
  border-radius: 10px;
  padding: 16px 48px;
  outline: none;
}

.AnimationContainer {
  position: relative;
  overflow: hidden;
  max-width: 150px;
  height: 16px;
  display: inline-block;
  background: #0752f9;
  text-decoration: none;
}

.AnimationContainer::after {
  animation: shine 3s ease-in-out  infinite;
  animation-fill-mode: forwards;  
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 170%;
  height: 400%;
  opacity: 0;
  transform: rotate(20deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right, 
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 1s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
  }
}

@media (min-width: 769px) {
  .ActionBtn {
    line-height: 1.23;
  }

  .BookText {
    display: inline;
    font-family: QuincyCF;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
  }

  .MaxContainer, .MaxContainerCTA {
    max-width: 900px;
    margin: auto;
  }
}
