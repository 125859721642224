.Unordered {
  padding: 24px 40px 32px 40px;
  max-width: 414px;
  background-color: #ecfdf5;
}

.Unordered li {
  padding-bottom: 50px;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-left: solid 1px #707070;
}

.Unordered li:last-child {
  border-left: transparent 1px solid;
  padding-bottom: 0;
}

.Point {
  min-width: 14px;
  height: 14px;
  background-color: #707070;
  border-radius: 100%;
  z-index: 0;
  position: relative;
  left: -8px;
  /* top: 5px; */
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -5px;
}

.Title {
  font-family: ModernEra;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Msg {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}

@media (min-width: 769px) {
  .Unordered {
    display: none;
  }

  .PossAdvDesktop {
    display: block !important;
  }
}
