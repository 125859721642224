.Card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  height: 100%;
  padding: 16px;
  margin: 0 6px;
}

.HeadContainer {
  display: flex;
  flex-direction: row;
}

.PhotoName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ProfilePicture {
  height: 66px;
  margin-right: 16px;
}

.Name {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.NameStars {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1 1;
  align-items: flex-start;
}

.Check {
  width: 20px;
  align-self: flex-end;
}

.Star {
  width: 10px;
  margin-right: 6px;
  margin-top: 6px;
}

.ReviewRow {
  display: flex;
  flex-direction: row;
}

.ReviewColumn {
  display: flex;
  flex-direction: column;
}

.Summary {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  margin: 16px 0;
}

.Review {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

@media (min-width: 1024px) {
  .Name {
    font-size: 23px;
  }

  .Summary {
    font-size: 19px;
  }
}