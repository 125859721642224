.MainContainer {
  background-color: #fdf6c3;
  padding: 24px;
}

.MainContainerList {
  padding: 12px !important;
}

.MainContainerKit {
  background-color: #fce1e4 !important;
  padding: 16px;
  padding-right: 10%;
}

.WarningIcon {
  background: url('../../Assets/images/svg/warning.svg');
  height: 27px;
  width: 29px;
  display: block;
}

.MessageContainer {
  flex: 4;
}

.MessageListContainer {
  flex: 5;
}

.MoonsPossAdvText {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
}

.ListItem {
  margin-bottom: 16px;
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.ListItem:last-child {
  margin-bottom: 0;
}

@media (min-width: 769px) {
  .MainContainer {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 48px;
    border-radius: 24px 24px 24px 24px;
    padding: 32px;
  }


  .MainContainerKit {
    margin-top: 48px;
  }

  .MoonsPossAdvText {
    font-size: 26px;
  }

  .MoonPossAdvContainer {
    max-width: 547px;
  }

  .ListItem {
    font-size: 26px;
  }
}

@media (min-width: 1300px) {
  .MoonPossAdvContainer {
    max-width: none;
  }
}