.TabletContainer{
  display: none;
}

.CarouselTablet {
  display: flex;
  height: 100%;
  align-self: center;
  margin: 0 80px;
  width: 100%;
  padding-bottom: 5px;
  overflow: hidden;
  position: relative;
}

.SlideshowContainerTable {
  position: relative;
  display: flex;
  left: 0;
}

.SliderMoveTablet {
  width: 150%;
  display: flex;
}

.StepContainerImagesTable{
  width: 100%;
  display: flex;
  padding: 0 2% 0 2%;
}
.CarouselImage{
  align-self: center;
  width: 100%;
}

.PhotoTablet{
  flex-direction: column;
  width: 30%;
  border-radius: 45px;
  display: inline-flex;
  margin: 0 18px 8px 18px;
  align-self: stretch;
}

.CarouselHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 52px;
}

.HeaderTitle{
  font-family: QuincyCF;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  text-align: center;
}

.SliderActive {
  border: none;
  width: 10px;
  height: 10px;
  background-color: #99bffc;
  border-radius: 5px;
  opacity: .5;
  z-index: 100;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.SliderBootOut {
  align-self: center;
  bottom: 5px;
  z-index: 1;
  height: 20px;
  text-align: center;
}

.SliderBotton {
  border: none;
  transition: opacity .25s;
  width: 6px;
  height: 6px;
  background-color: #d8d8d8;
  border-radius: 3px;
  opacity: .5;
  z-index: 100;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
}

.Wrapper {
  overflow: hidden;
  margin: 0 auto;
  /*width: calc(100% - 78px);  margin_total: 57 margin_item: 18   2*(57-18) = 78*/
}

.Circle {
  width: 70px;
  height: 70px;
  background-color: rgba(0, 0, 0, 0.3);
  border-color:  rgba(248, 244, 244, 0.3);
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  font-size: 2.5rem;
  line-height: 4rem;
  font-size: 26px;
  position: absolute;
  margin-top: 11%;
  z-index: 1;
  outline: none;
  border: 0;
}

.Next {
  right: 13px;
  background-image: url('../assets/svg/next.svg');
  cursor: pointer;    
}

.Prev {
  left: 13px;
  background-image: url('../assets/svg/before.svg');
  cursor: pointer;    
}

@media (min-width: 700px) {
  .TabletContainer {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
  }
} 

@media (min-width: 760px) {
  .Circle {
    margin-top: 12%;
  }
} 
@media (min-width: 889px) {
  .Circle {
    margin-top: 9%;
  }
} 

@media (min-width: 1000px) {
  .Circle {
    margin-top: 6%;
  }
} 

/* @media (min-width: 1150px) {
  .Circle {
    margin-top: 5%;
  }
} */