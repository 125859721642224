.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.MessageContainer {
  display: flex;
  flex-direction: column;
  background-color: #ecfdf5;
}

.Title {
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin: 32px 0px 24px 0px;
}

.Msg, .CustomerCare {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #252525;
  margin: 0px 32px;
}

.Msg {
  margin-bottom: 24px;
}

.PaidAmount {
  font-family: ModernEra;
  font-size: 24px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  margin-bottom: 24px;
}

.CustomerCare {
  margin-bottom: 32px;
}

@media (min-width: 769px) {
  .MessageContainer {
    border-radius: 24px 24px 24px 24px;
    margin-bottom: 32px;
  }

  .PromoText {
    top: 32px;
  }

  .Title, .PaidAmount {
    font-size: 34px;
  }

  .Msg, .CustomerCare {
    font-size: 26px;
  }
}