.MainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 72px;
  padding: 0px 32px;
}

.ApolloImgContainer {
  margin-bottom: 32px;
}

.ApolloMore {
  height: 146px !important;
}

.ApolloMoons {
  height: 166px;
}

.MsgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Message, .Description {
  font-family: QuincyCF;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Message {
  font-size: 30px;
  line-height: 1.27;
}

.Description {
  margin-top: 16px;
  font-size: 24px;
  line-height: 1.25;
}

.DescriptionSmaller {
  margin-top: 16px;
  font-size: 20px;
  line-height: 1.3;
}

.MessageMoons {
  min-width: 274px;
}

@media (min-width: 769px) {
  .MainContainer {
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 80px;
  }

  .MsgContainer {
    padding: 0px 0px 0px 16px;
  }

  .ApolloImgContainer {
    margin-bottom: 0;
  }

  .Message {
    font-size: 40px;
    line-height: 1.25;
  }

  .Description, .DescriptionSmaller {
    font-size: 34px;
    line-height: 1.24;
  }
}