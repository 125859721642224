.MainContainer {
  margin-bottom: 42px;
  margin-top: 32px;
  padding: 0px 32px;
}

.RowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Title {
  font-family: QuincyCF;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.ApolloImgContainer {
  display: flex;
  justify-content: center;
  margin-top: 42px;
}

.ApolloImg {
  height: 166px;
}

.BtnContainer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 10%;
}

.ActionBtn {
  /* text */
  font-family: ModernEra;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  /* btn */
  background-color: #0752f9;
  border: 0;
  border-radius: 10px;
  padding: 16px 48px;
}

@media (min-width: 769px) {
  .MainContainer {
    padding: 0px;
    max-width: 900px;
    margin: auto;
  }

  .RowContainer {
    flex-direction: row;
  }

  .Title {
    font-size: 34px;
    line-height: 1.24;
  }

  .ApolloImgContainer {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .ActionBtn {
    line-height: 1.23;
  }
}