.MainContainer {
  background-color: #eef9fc;
  margin-top: 48px;
  padding: 0px 32px;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
}

.DoubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
}

.DrPrado {
  width: 90px;
  margin-right: 16px;
}

.DrText {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  /* max-width: 243px; */
}

.PrimaryTextMoons, .SecondaryTextNeedMed {
  margin-top: 16px;
}

.PrimaryTextMoons, .SecondaryTextMoons, .SecondaryTextNeedMed {
  margin-bottom: 16px;
}

.PrimaryTextNoCand {
  margin-bottom: 8px;
  margin-top: 16px;
}

.SecondaryTextNoCand {
  margin-bottom: 16px;
}

.PrimaryTextMoonsAdv {
  max-width: none;
}

.LoaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px;
}

.BorderDrImg {
  border: solid 4px #dee3f2;
  border-radius: 50%;
}

@media (min-width: 769px) {
  .MainContainer {
    height: 230px;
    border-radius: 24px 24px 24px 24px;
  }

  .PrimaryTextMoons, .PrimaryTextMoonsAdv, .PrimaryTextNoCand {
    max-width: 496px;
    font-size: 26px;
  }

  .PrimaryTextNoCand {
    margin-bottom: 16px;
  }

  .SecondaryTextMoons {
    max-width: none;
    font-size: 25px;
  }

  .SecondaryTextNoCand {
    max-width: 496px;
    font-size: 25px;
  }

  .SecondaryTextNeedMed {
    max-width: none;
    font-size: 25px;
    padding-right: 32px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .DrPrado {
    width: 166px;
  }

  .BorderDrImg {
    border-width: 5px;
  }
}

@media (min-width: 1200px) {
  .PrimaryTextMoons, .PrimaryTextMoonsAdv, .PrimaryTextNoCand {
    max-width: none;
  }

  .SecondaryTextNoCand {
    max-width: none;
  }
}